import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Credential } from '../models/credential.model';

@Injectable()
export class AWSService {
    CREDENTIAL_TAG: string = "32ab260c-4c7d-43c1-9db6-b237145da547";

    _instances: BehaviorSubject<[]> = new BehaviorSubject<[]>([]);
    instances = this._instances.asObservable();

    constructor() {

    }

    getCredential() {
        let credential = localStorage.getItem(this.CREDENTIAL_TAG);
        if (credential) {
            return JSON.parse(credential);
        }
        else {
            return null;
        }
    }

    saveCredentials(credential: Credential) {
        localStorage.setItem(this.CREDENTIAL_TAG, JSON.stringify(credential));
    }

    closeSession() {
        localStorage.setItem(this.CREDENTIAL_TAG, null);
        this._instances.next([]);
    }
}