import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SessionService } from './session.service';


//
// ──────────────────────────────────────────────────────────────────────────────────────────────────── I ──────────
//   :::::: H T T P   I N T E R C E P T O R   S E R V I C E   C L A S S : :  :   :    :     :        :          :
// ──────────────────────────────────────────────────────────────────────────────────────────────────────────────
//


/**
* Injects auth token and catches errors.
*
* @export
* @class HttpInterceptorService
* @implements {HttpInterceptor}
*/
@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
    //
    // ─── CONSTRUCTOR ────────────────────────────────────────────────────────────────
    //

    constructor(
        private sessionService: SessionService,
    ) {

    }
    //
    // ─── METHODS ────────────────────────────────────────────────────────────────────
    //


    /**
     * Intercepts HTTP calls and responses.
     *
     * @param {HttpRequest<any>} request
     * @param {HttpHandler} next
     * @returns {Observable<HttpEvent<any>>}
     * @memberof HttpInterceptorService
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        //console.log(request);
        // Authorization headers only for API urls.
        if (request.url.includes('/api')) {

            // Set JSON headers.
            // request = request.clone({
            //     setHeaders: {
            //         // 'Accept': 'application/json',
            //         // 'Content-Type': 'application/json'
            //     }
            // });

            // Get current sessions.
            let session = this.sessionService.getCurrentSession();
            let token: string = null;

            // Primary token.
            if (session && session.token) {
                token = session.token;
            }

            // Set authorization header if exists.
            if (token != null) {
                request = request.clone({
                    setHeaders: {
                        'Authorization': 'Bearer ' + token
                    }
                });
            }
        }

        // Return response.
        return next
            .handle(request)
            .pipe(
                catchError((_error) => {
                    return throwError(_error);
                })
            );
    }
}
