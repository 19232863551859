import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './_shared/services/auth-guard.service';
import { AWSService } from './_shared/services/aws.service';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'instances',
    loadChildren: () => import('./instances/tab2.module').then(m => m.Tab2PageModule),
    canActivate: [AuthGuardService]
  },

  {
    path: 'stats',
    loadChildren: () => import('./stats/stats.module').then(m => m.StatsModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'rules',
    loadChildren: () => import('./rules/rules.module').then(m => m.RulesModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'users',
    loadChildren: () => import('./users/users.module').then(m => m.UserModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'change',
    loadChildren: () => import('./change/change.module').then(m => m.ChangeModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'instance-operations',
    loadChildren: () => import('./intancescrud/instancescrud.module').then(m => m.InstancesCrudModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'instance-update',
    loadChildren: () => import('./intanceupdate/intanceupdate.module').then(m => m.IntanceupdateModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'aviso',
    loadChildren: () => import('./aviso/aviso.module').then(m => m.AvisoModule)
  },
  {
    path: 'terminos',
    loadChildren: () => import('./terminos/terminos.module').then(m => m.TerminosModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true })
  ],
  exports: [RouterModule],
  providers: [
    AWSService
  ]
})
export class AppRoutingModule { }
