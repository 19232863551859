import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionService } from './session.service';

@Injectable()
export class AuthGuardService implements CanActivate {

    constructor(
        private sessionService: SessionService,
        private router: Router
    ) {

    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        const session = this.sessionService.getCurrentSession();

        if (!session) {
            if (route.url.length == 0) {
                return true
            }
            else {
                this.router.navigate(['/']);
            }
            return false;
        }
        else {
            if (route.url.length == 0) {
                this.router.navigate(['/home']);
                return false;
            }
        }
        return true;
    }
}
